<template>
  <div class="code-section">
    <div class="title-section flex">
      <div class="flex-one w-full text-2xl font-bold">
        Historique des recharges
      </div>
      <div class="flex-one w-1/3  text-right">
        <button
          class="px-4 -mt-3 rounded py-4 bg-green text-white"
          @click="wantToGenerate = !wantToGenerate"
        >
          Générer un code
        </button>
      </div>
    </div>
    <div class="history-list-section mt-10">
      <div class="flex p-5 header-history bg-white">
        <div class="flex-one w-1/3 font-semibold text-grey">
          Client
        </div>
        <div class="flex-one  w-1/3 font-semibold text-grey">
          Date
        </div>
        <div class="flex-one w-1/3 font-semibold text-grey">
          Status
        </div>
        <div class="flex-one w-1/3 font-semibold text-grey">
          CODE
        </div>
        <div class="flex-one  w-1/3 font-semibold text-grey">
          Montant
        </div>
      </div>
      <div class="history-list bg-white">
        <code-re
          v-for="(item, key) in allRealCodes"
          :key="item + key"
          :code="item"
        />
      </div>
    </div>
    <genare-code
      v-if="wantToGenerate"
      @closeGenerate="closeGenerate"
      @successGenerateCode="successGenerateCode"
    />
    <success-generate
    v-if="successGenerate"
    @emitCloseCodeSuccess="emitCloseCodeSuccess"
    :code="code"/>
  </div>
</template>

<script>
import CodeRe from '../../components/helper/rema-components/code'
import GenareCode from '../../components/helper/rema-components/genare-code'
import SuccessGenerate from '../../components/helper/rema-components/success-generate'
import { firebaseDb } from '../../main'
import { firebaseRef } from '../../rema-function/firebaseRef'
export default {
  name: 'Index',
  components: { SuccessGenerate, GenareCode, CodeRe },
  data () {
    return {
      wantToGenerate: false,
      successGenerate: false,
      code: '',
      allRealCodes: []
    }
  },
  methods: {
    closeGenerate (answer) {
      this.wantToGenerate = answer
    },
    emitCloseCodeSuccess (answer) {
      this.successGenerate = answer
    },
    successGenerateCode (answer) {
      this.wantToGenerate = false
      this.code = answer.uid
      this.successGenerate = true
    }
  },
  firebase () {
    return {
      allRealCodes: firebaseDb.ref(firebaseRef.walletCode).orderByChild('createdAt')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/style/sass/variables";
  .history-list {
    margin-top: .3rem;
  }
</style>
