<template>
  <div class="success-generate" @click="emitClose">
    <popup-base @clickOnBackground="emitClose">
      <div class="bg-white w-1/2 p-4 rounded success-section">
        <h3 class="text-center">
          <svg-icon
            name="ic-brawo"
            class="h-12 w-12"
            original
            />
        </h3>
        <h3 class="text-center text-green font-bold mt-4 text-3xl">Succès</h3>
        <h3 class="success-text text-xl text-center mt-4 font-light">Code de recharge REMA crée avec succès</h3>
        <div class="code-section border rounded text-center mt-4">
          <h3 class="text-xl font-light text-grey text-center mt-4">{{ code }}</h3>
        </div>
        <div class="success-button mt-4">
          <button @click="emitClose" class="code-section uppercase px-6 text-center w-full text-xl code-input font-bold text-white rounded bg-green px-6">
            Ok
          </button>
        </div>
      </div>
    </popup-base>
  </div>
</template>

<script>
import PopupBase from './popup-base'
export default {
  name: 'SuccessGenerate',
  components: { PopupBase },
  props: {
    code: {
      type: String,
      default: 'AZERTY'
    }
  },
  methods: {
    emitClose () {
      this.$emit('emitCloseCodeSuccess', false)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
  .success-section {
    margin: auto;
  }
  .code-section {
    height: 3.8rem;
  }
</style>
