<template>
  <div
    class="generate-popup"
    @click="emitClose"
  >
    <popup-base @clickOnBackground="emitClose">
      <div class="generate-popup bg-white w-1/2 p-4 rounded">
        <h3 class="text-2xl font-bold text-green">
          Générer un code
        </h3>
        <div class="code-input border  mt-4 rounded">
          <input
            v-model="client"
            class="p-2 h-full w-full"
            placeholder="Client"
          >
        </div>
        <div class="code-input border  mt-6 rounded">
          <input
            v-model="amount"
            class="p-2 h-full w-full"
            placeholder="Montant en euro"
            @keypress="isNumberOnly"
          >
        </div>
        <div class="button-section mt-6">
          <button @click="createCode" class="px-6 text-center w-full text-xl code-input font-bold text-white rounded bg-green px-6">
            <span v-if="!loading">Generer</span>
            <pulse-loader
              v-if="loading"
              color="#ffffff"
            />
          </button>
        </div>
      </div>
    </popup-base>
  </div>
</template>

<script>
import PopupBase from './popup-base'
import PulseLoader from 'vue-spinner/src/PulseLoader'
import { firebaseDb } from '../../../main'
import { firebaseRef } from '../../../rema-function/firebaseRef'
export default {
  name: 'GenareCode',
  components: { PulseLoader, PopupBase },
  data () {
    return {
      client: '',
      amount: '',
      loading: false
    }
  },
  methods: {
    emitClose () {
      this.$emit('closeGenerate', false)
    },
    createCode () {
      if (this.amount !== '' && this.client !== '') {
        let vm = this
        this.loading = true
        let randomstring = Math.random().toString(36).slice(-8).toUpperCase()
        let code = {
          amount: this.amount,
          isValid: true,
          client: this.client,
          uid: randomstring,
          createdAt: new Date().getTime()
        }
        firebaseDb.ref(firebaseRef.walletCode).child(randomstring).set(code).then((res) => {
          vm.$emit('successGenerateCode', code)
        }).catch((err) => {
          alert(err.toString())
        })
      } else {
        alert('Tous les champs sont obligatoires.')
      }
    },
    isNumberOnly ($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which)
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
        $event.preventDefault()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
  .generate-popup {
    margin: auto;
  }
  .code-input {
    height: 3.8rem;
  }
</style>
