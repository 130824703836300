<template>
  <div class="code-section">
    <div class="flex p-5 header-history bg-white">
      <div class="flex-one w-1/3 font-normal">
        {{ code.client }}
      </div>
      <div class="flex-one  w-1/3 font-normal">
        {{ new Date(code.createdAt).toLocaleDateString('en-GB') }}
      </div>
      <div class="flex-one w-1/3  font-normal ">
        <span v-if="code.isValid" class="text-green">Actif</span>
        <span v-if="!code.isValid" class="text-red">Expiré</span>
      </div>
      <div class="flex-one w-1/3 font-normal ">
        {{ code.uid }}
      </div>
      <div class="flex-one  w-1/3 font-normal">
        {{ code.amount }} €
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CodeRe',
  props: {
    code: {
      type: Object,
      default: function () {
        return {
          amount: 200,
          isValid: false,
          client: 'ee',
          uid: 'ee',
          createdAt: new Date().getTime()
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
</style>
